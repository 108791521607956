// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import "bootstrap"
// require("../stylesheets/application.scss")
import 'stylesheets/application'

// require("../stylesheets/application.scss")
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


document.bg_color_change = (event)=> {
  const input = document.getElementById('bg-color-text')
  input.value = event.value
}

document.bg_input_color_change = (event) => {
  const input = document.getElementById('bg-color-input')
  input.value = event.value;
}

document.get_merchants = (merchant_val)=> {
  let url = '/merchants';

  fetch(url)
  .then(res => res.json())
  .then((merchants) => {
    const select = document.getElementById('card_merchant_id')
    merchants.forEach(merchant => {
      const opt = document.createElement('option');
      opt.value = merchant.id;
      opt.innerHTML = merchant.name;
      select.appendChild(opt);
    });
    select.value = merchant_val;
  })
}
